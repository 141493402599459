/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23f25237a0dd6e5e.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4211d34fd1c6f59f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/527ad038b46df541.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/10ca58f12dc31ec2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/61b81e7bde89ba40.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}.__className_f98501 {font-family: 'Nunito Sans';font-style: normal
}.__variable_f98501 {--font-nunito-sans: 'Nunito Sans'
}

:root {
  --white: #FFF;
  --lightest: #F8F8F8;
  --black: #050621;
  --lightest: #f8f8f8;
  --light: #efebe7;
  --medium: #5a7282;
  --dark: #2d3134;
  --darkest: #0E1117;
  --max-width: 1280px;
  --font-family: var(--font-nunito-sans);
  --border-image-source: linear-gradient(to right, #8df9a0, #ff8df4, #ffe03a, #9c52ff);
  --border: 1px solid #e9e9e980;
  --blue: blue;
  --green: green;
  --yellow: #FFC107;
  --dark-blue: #141a99;
  --light-blue: #001d6810;
}

* {
  box-sizing: border-box;
  color: var(--black);
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: var(--lightest);
  color: var(--black);
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button {
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  all: unset;
}

input {
  border: none;
  font-size: 1rem;
  outline: none;
}

input::placeholder {
  color: var(--medium);
}

h1 {
  font-size: clamp(2rem, 10svw, 3.5rem);
  letter-spacing: -1px;
}

h2 {
  font-size: clamp(1.6rem, 4svw, 2.2rem);
  margin-bottom: 24px;
  letter-spacing: -1px;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
}

h4 {
  font-size: 1.44rem;
  margin-bottom: 8px;
  letter-spacing: -0.25px;
}

h5 {
  font-size: 1.16rem;
  margin-bottom: 4px;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--black);
  line-height: 1.2;
  font-family: var(--font-family);
  font-weight: 700;
}

/* SCROLLBARS */
::-webkit-scrollbar {
  background: transparent;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #001d6830;
}
.styles_header__tubcQ {
  background-color: var(--lightest);
  border-bottom: 0.5px solid;
  border-image-slice: 1;
  border-width: 0.5px;
  border-image-source: var(--border-image-source);
}

.styles_headerContainer__3e5zX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 16px;
}

.styles_headerWrapper__42fzv {
  display: flex;
  align-items: center;
  gap: 16px;
}
.styles_logo__YlxQn {
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
}

.styles_logo__YlxQn div {
  letter-spacing: -0.5px;
  font-size: 30px;
  font-weight: 700;
}
.styles_profilePopoverContainer__sqr_C {
  position: relative;
  display: inline-block;
}

.styles_profilePopoverWrapper__jzYov {
  background-color: transparent;
  padding: 4px;
  position: absolute;
  top: 100%;
  right: -16px;
  width: 300px;
  padding: 16px;
  z-index: 2;
}

.styles_profilePopoverContainer__sqr_C .styles_profilePopoverWrapper__jzYov {
  visibility: hidden;
}

.styles_profilePopoverContainer__sqr_C:hover .styles_profilePopoverWrapper__jzYov {
  visibility: visible;
}

.styles_profilePopover___sVOn {
  border: var(--border);
  border-radius: 8px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 16px;
}

.styles_profilePopover___sVOn div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.styles_profilePopover___sVOn p {
  color: var(--black);
  font-weight: 600;
  font-size: 1.2rem;
  width: 100%;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  margin-top: 4px;
  text-overflow: ellipsis;
  text-align: center;
}

.styles_profilePopover___sVOn a {
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
}

.styles_profilePopover___sVOn a:hover {
  background-color: var(--light-blue);
}

.styles_profilePopover___sVOn hr {
  margin: 16px 0;
  border: none;
  border-top: var(--border);
}

.styles_profilePopover___sVOn button {
  padding: 0px 16px;
}

.styles_profilePopover___sVOn button:hover {
  color: var(--blue);
}
.styles_footer__BtGOh {
  border-top: 0.5px solid;
  border-image-slice: 1;
  border-width: 0.5px;
  border-image-source: var(--border-image-source);
}

.styles_footerWrapper__hUPvW {
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 32px 16px;
}

.styles_footer__BtGOh h3 {
  color: var(--medium);
  letter-spacing: 0.25px;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.styles_footerNavigation__C3FNd {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 32px;
  gap: 32px;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 40px;
}

.styles_footerNavigation__C3FNd div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.styles_footerNavigation__C3FNd a {
  font-size: 1rem;
  font-weight: 400;
}

.styles_footerNavigation__C3FNd a:hover {
  color: var(--blue);
  text-decoration: underline;
}

.styles_footerDisclaimer__CpGG3 {
  font-size: 0.9rem;
  line-height: 1.4;
}

.styles_footer__BtGOh hr {
  margin: 24px 0px;
  border: none;
  border-top: 0.5px solid #22222220;
}

.styles_footerNote__7W5Rq div {
  color: var(--medium);
  font-size: 0.9rem;
  text-align: center;
}

.styles_footerNote__7W5Rq a {
  color: var(--dark-blue);
  font-weight: 500;
}

.styles_footerNote__7W5Rq a:hover {
  color: var(--blue);
}

@media screen and (min-width: 568px) {
  .styles_footerNote__7W5Rq {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 16px;
    gap: 16px;
  }

  .styles_footerNote__7W5Rq div:first-child {
    text-align: left;
  }

  .styles_footerNote__7W5Rq div:last-child {
    text-align: right;
  }
}
.styles_bannerModal__wseTX {
  background-color: #23242727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100svh;
  z-index: 1000;
}

.styles_bannerModalCard__FUPbA {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  max-width: 420px;
  padding: 24px;
}

.styles_bannerCardTitle__gP7ZF {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 8px;
  letter-spacing: -0.25px;
}

.styles_bannerCardImg___MnLT {
  margin-bottom: 16px;
}

.styles_bannerCardImg___MnLT img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  object-fit: contain;
  overflow: hidden;
}

.styles_bannerModalCard__FUPbA p {
  margin-bottom: 16px;
}

.styles_bannerBtn__3o1Oo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.styles_bannerBtn__3o1Oo button {
  border: 1px solid var(--dark-blue);
  background-color: var(--white);
  border-radius: 8px;
  color: var(--dark-blue);
  cursor: pointer;
  font-weight: 600;
  margin-top: 8px;
  padding: 8px 16px;
}

.styles_bannerModalCard__FUPbA ul li {
  list-style-type: circle;
  list-style-position: inside;
  margin-bottom: 4px;
  padding-left: 2px;
}
