.bannerModal {
  background-color: #23242727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100svh;
  z-index: 1000;
}

.bannerModalCard {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  max-width: 420px;
  padding: 24px;
}

.bannerCardTitle {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 8px;
  letter-spacing: -0.25px;
}

.bannerCardImg {
  margin-bottom: 16px;
}

.bannerCardImg img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  object-fit: contain;
  overflow: hidden;
}

.bannerModalCard p {
  margin-bottom: 16px;
}

.bannerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bannerBtn button {
  border: 1px solid var(--dark-blue);
  background-color: var(--white);
  border-radius: 8px;
  color: var(--dark-blue);
  cursor: pointer;
  font-weight: 600;
  margin-top: 8px;
  padding: 8px 16px;
}

.bannerModalCard ul li {
  list-style-type: circle;
  list-style-position: inside;
  margin-bottom: 4px;
  padding-left: 2px;
}