:root {
  --white: #FFF;
  --lightest: #F8F8F8;
  --black: #050621;
  --lightest: #f8f8f8;
  --light: #efebe7;
  --medium: #5a7282;
  --dark: #2d3134;
  --darkest: #0E1117;
  --max-width: 1280px;
  --font-family: var(--font-nunito-sans);
  --border-image-source: linear-gradient(to right, #8df9a0, #ff8df4, #ffe03a, #9c52ff);
  --border: 1px solid #e9e9e980;
  --blue: blue;
  --green: green;
  --yellow: #FFC107;
  --dark-blue: #141a99;
  --light-blue: #001d6810;
}

* {
  box-sizing: border-box;
  color: var(--black);
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: var(--lightest);
  color: var(--black);
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button {
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  all: unset;
}

input {
  border: none;
  font-size: 1rem;
  outline: none;
}

input::placeholder {
  color: var(--medium);
}

h1 {
  font-size: clamp(2rem, 10svw, 3.5rem);
  letter-spacing: -1px;
}

h2 {
  font-size: clamp(1.6rem, 4svw, 2.2rem);
  margin-bottom: 24px;
  letter-spacing: -1px;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
}

h4 {
  font-size: 1.44rem;
  margin-bottom: 8px;
  letter-spacing: -0.25px;
}

h5 {
  font-size: 1.16rem;
  margin-bottom: 4px;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--black);
  line-height: 1.2;
  font-family: var(--font-family);
  font-weight: 700;
}

/* SCROLLBARS */
::-webkit-scrollbar {
  background: transparent;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #001d6830;
}