.logo {
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
}

.logo div {
  letter-spacing: -0.5px;
  font-size: 30px;
  font-weight: 700;
}